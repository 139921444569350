import { Fragment } from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import format from 'bloko/common/format';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import numberFormatter from 'bloko/common/numberFormatter';
import { formatNewLine } from 'bloko/common/trl';

import translation from 'lux/components/translation';

const TrlKeys = {
    fileTooLarge: 'employer.logo.upload.notify.fileTooLarge',
    unsupportedFileFormat: 'employer.logo.upload.notify.unsupportedFileFormat',
    uploadSuccess: 'employer.logo.upload.notify.uploadSuccess',
    uploadTimeout: 'employer.logo.upload.notify.uploadTimeout',
    uploadError: 'employer.logo.upload.notify.uploadError',
    autoSearchSavedSuccessText: 'vacancySearchResults.saveSearch.success.text',
    autoSearchSavedSuccessTitle: 'vacancySearchResults.saveSearch.success.title',
    autoSearchSavedApplicantTitle: 'serp.savedSearch.success.1',
    autoSearchSavedApplicantTeaser: 'serp.savedSearch.teaser.autosearch',
    autoSearchSavedApplicantMessage: 'serp.savedSearch.success.2.vacancy',
    autoSearchTooManyErrorsTitle: 'employer.sidebar.subscribe.tooMany.title',
    autoSearchTooManyErrorsMessage: 'employer.sidebar.subscribe.tooMany.message',
    autoSearchTooManyErrorsTeaser: 'employer.sidebar.subscribe.tooMany.teaser',
};

const FileTooLarge: TranslatedComponent = ({ trls }) => formatNewLine(trls[TrlKeys.fileTooLarge]);
export const employerLogoFileTooLarge = { Element: translation(FileTooLarge), kind: 'error', autoClose: true };

const UnsupportedFileFormat: TranslatedComponent = ({ trls }) => formatNewLine(trls[TrlKeys.unsupportedFileFormat]);
export const employerLogoUnsupportedFileFormat = {
    Element: translation(UnsupportedFileFormat),
    kind: 'error',
    autoClose: true,
};

const UploadSuccess: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.uploadSuccess]}</>;
export const employerLogoUploadSuccess = { Element: translation(UploadSuccess), kind: 'ok', autoClose: true };

const UploadTimeout: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.uploadTimeout]}</>;
export const employerLogoUploadTimeout = { Element: translation(UploadTimeout), kind: 'error', autoClose: true };

export interface EmployerLogoUploadErrorProps {
    logoMaxWidth: number;
    logoMaxHeight: number;
}
const UploadError: TranslatedComponent<EmployerLogoUploadErrorProps> = ({ trls, logoMaxWidth, logoMaxHeight }) => {
    const maxWidthFormatted = numberFormatter.format(String(logoMaxWidth));
    const maxHeightFormatted = numberFormatter.format(String(logoMaxHeight));
    return <>{format(trls[TrlKeys.uploadError], { '{0}': maxWidthFormatted, '{1}': maxHeightFormatted })}</>;
};
export const employerLogoUploadError = {
    Element: translation(UploadError),
    kind: 'error',
    autoClose: true,
    autoCloseDelay: 10000,
};

const AutosearchSaved: TranslatedComponent = ({ trls }) => {
    return (
        <Fragment>
            <NotificationHeading>{trls[TrlKeys.autoSearchSavedSuccessTitle]}</NotificationHeading>
            <div>{trls[TrlKeys.autoSearchSavedSuccessText]}</div>
        </Fragment>
    );
};
export const employerSidebarAutosearchSaved = {
    Element: translation(AutosearchSaved),
    kind: 'ok',
    autoClose: true,
};

const AutosearchSavedApplicant: TranslatedComponent = ({ trls }) => {
    return (
        <Fragment>
            <NotificationHeading>
                {trls[TrlKeys.autoSearchSavedApplicantTitle]}{' '}
                <BlokoLink disableVisited Element={Link} to="/applicant/autosearch">
                    {trls[TrlKeys.autoSearchSavedApplicantTeaser]}
                </BlokoLink>
            </NotificationHeading>
            <div>{trls[TrlKeys.autoSearchSavedApplicantMessage]}</div>
        </Fragment>
    );
};
export const employerSidebarAutosearchSavedApplicant = {
    Element: translation(AutosearchSavedApplicant),
    kind: 'ok',
    autoClose: true,
};

const AutosearchTooManyError: TranslatedComponent = ({ trls }) => (
    <Fragment>
        <NotificationHeading>{trls[TrlKeys.autoSearchTooManyErrorsTitle]}</NotificationHeading>
        <div>
            {trls[TrlKeys.autoSearchTooManyErrorsMessage]}{' '}
            <BlokoLink disableVisited Element={Link} to="/applicant/autosearch">
                {trls[TrlKeys.autoSearchTooManyErrorsTeaser]}
            </BlokoLink>
        </div>
    </Fragment>
);
export const employerSidebarAutosearchTooMany = {
    Element: translation(AutosearchTooManyError),
    kind: 'error',
    autoClose: true,
};
