import React, { Fragment, FC, PropsWithChildren } from 'react';

import { useSelector } from 'lux/modules/useSelector';

import { Vacancy } from 'lux/components/Employer/VacanciesGroupsByRegion/VacancyGroupsTypes';
import VacancyItem from 'lux/components/Employer/VacanciesGroupsByRegion/VacancyItem';

interface VacanciesProps {
    vacanciesList: Array<Vacancy>;
}

const Vacancies: FC<VacanciesProps & PropsWithChildren> = ({ vacanciesList, children }) => {
    const { organizationFormId } = useSelector((state) => state.employerInfo);
    return (
        <Fragment>
            {!!vacanciesList?.length &&
                vacanciesList.map((vacancy) => (
                    <VacancyItem key={vacancy.vacancyId} {...vacancy} organizationFormId={organizationFormId} />
                ))}
            {children}
        </Fragment>
    );
};

export default Vacancies;
