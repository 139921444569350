import { FC } from 'react';

import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';

import StaticImg from 'lux/components/StaticImg';

import styles from './qr-code.less';

const ChatBotQrCode: FC = () => {
    const { page, genericUserType, source } = useSelectorNonNullable((store) => store.chatBot);

    return (
        <StaticImg
            className={styles.qrCode}
            path={`/i/hh/chatbot/${page}/qr-telegram-${genericUserType}-${source}.png`}
            alt={'qr-code-telegram'}
        />
    );
};

export default ChatBotQrCode;
