import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SubscribeProvider from 'lux/components/Employer/Subscribe/SubscribeProvider';
// tempexp_26879_start
import WantSame from 'lux/components/VacancyView/WantSame';
// tempexp_26879_end
// На этой странице нужны динамические импорты с целью уменьшения чанка
// eslint-disable-next-line @hh.ru/import-rules/no-internal-modules
import EmployerConstructor from 'lux/pages/EmployerConstructor';
// eslint-disable-next-line @hh.ru/import-rules/no-internal-modules
import EmployerSimple from 'lux/pages/EmployerSimpleEdit';

import EmployerChameleon from 'lux/pages/EmployerView/components/EmployerChameleon';

const EmployerView = ({ hasConstructor, hasChameleon, employerId }) => {
    if (hasChameleon) {
        return <EmployerChameleon />;
    }

    return (
        <SubscribeProvider>
            {hasConstructor ? (
                <>
                    <EmployerConstructor />
                    {/* tempexp_26879_nextline */}
                    <WantSame keyPrefix="employerId" id={String(employerId)} />
                </>
            ) : (
                <EmployerSimple />
            )}
        </SubscribeProvider>
    );
};

EmployerView.propTypes = {
    hasConstructor: PropTypes.bool,
    hasChameleon: PropTypes.bool,
    // tempexp_26879_nextline
    employerId: PropTypes.number,
};

export default connect(({ employerConstructor, hasChameleon, employerInfo }) => ({
    hasConstructor: employerConstructor.hasConstructor,
    hasChameleon,
    // tempexp_26879_nextline
    employerId: employerInfo?.id,
}))(EmployerView);
