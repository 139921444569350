import { FC, ComponentPropsWithoutRef } from 'react';

import { useSubscribeContext } from 'lux/components/Employer/Subscribe/SubscribeContext';

const Subscribe: FC<ComponentPropsWithoutRef<'button'>> = ({ children, ...props }) => {
    const { handleSaveClick, saveSearchSubmitDisabled } = useSubscribeContext() || {};

    return (
        <button {...props} onClick={handleSaveClick} disabled={saveSearchSubmitDisabled}>
            {children}
        </button>
    );
};

export default Subscribe;
