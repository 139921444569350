import Button, { ButtonKind, ButtonType } from 'bloko/blocks/button';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Form from 'lux/components/Form';
import translation from 'lux/components/translation';

const TrlKeys = {
    link: 'vacancySearchResults.saveSearch.authRequired.link',
    text: 'vacancySearchResults.saveSearch.authRequired.text',
};

interface ShouldLoginProps {
    employerId: number;
}

const ShouldLogin: TranslatedComponent<ShouldLoginProps> = ({ employerId, trls }) => {
    return (
        <div data-qa="vacancy-upgrade-menu">
            <Text>{trls[TrlKeys.text]}</Text>
            <VSpacing base={6} />
            <Form action="/logon.do" method="get">
                <Button kind={ButtonKind.Primary} type={ButtonType.Submit}>
                    {trls[TrlKeys.link]}
                </Button>
                <input type="hidden" name="backUrl" value={`/employer/${employerId}`} />
            </Form>
        </div>
    );
};

export default translation(ShouldLogin);
