import { FC } from 'react';

import { VSpacing, HSpacingContainer } from '@hh.ru/magritte-ui';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';

import WantWorkHere from 'lux/components/Employer/Sidebar/WantWorkHere';
import PrepareEmployerHHRating from 'lux/components/EmployerHHRatingIcon/PrepareEmployerHHRating';
import EmployerReviewsWidgetSmall from 'lux/components/EmployerReviews/WidgetSmall';
import HrBrandIcon from 'lux/components/HrBrandIcon';
import useGetCompanyHHRatingBadge from 'lux/hooks/companies/useGetCompanyHHRatingBadge';
import { useSelector } from 'lux/modules/useSelector';

import OpenEmployerIcon from 'lux/components/Employer/ChameleonHeader/components/OpenEmployerIcon';

import styles from './styles.less';

const ChameleonHeader: FC = () => {
    const hrBrand = useSelector((state) => state.employerInfo.hrBrand);
    const badges = useSelector((state) => state.employerInfo.badges);
    const accepted = useSelector((state) => state.employerInfo.accepted);
    const employerHHRatingBadge = useGetCompanyHHRatingBadge(badges);

    return (
        <ColumnsWrapper>
            <Column l="16" m="12" s="8" xs="4">
                <WantWorkHere />
                <VSpacing default={24} />
                <div className={styles.employerChameleonHeader}>
                    <HSpacingContainer default={8}>
                        {<OpenEmployerIcon size="medium" />}
                        {employerHHRatingBadge && (
                            <PrepareEmployerHHRating
                                badge={employerHHRatingBadge}
                                size="medium"
                                dataQaPrefix="sidebar-"
                            />
                        )}
                        {hrBrand && <HrBrandIcon hrBrand={hrBrand} size="medium" dataQa="sidebar-hr-brand-icon" />}
                    </HSpacingContainer>
                    {accepted && <EmployerReviewsWidgetSmall />}
                </div>
                <VSpacing default={28} />
            </Column>
        </ColumnsWrapper>
    );
};

export default ChameleonHeader;
