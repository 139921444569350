import { FC } from 'react';

import useToggleState from 'lux/hooks/useToggleState';

import SaveSearch from 'lux/components/Employer/Sidebar/SubscribeModal/Email/SaveSearch';
import ShouldLogin from 'lux/components/Employer/Sidebar/SubscribeModal/Email/ShouldLogin';

interface SubscribeModalProps {
    autosearchName: string;
    employerId: number;
    toggleShowModal: () => void;
}

const SubscribeModal: FC<SubscribeModalProps> = ({ autosearchName, employerId, toggleShowModal }) => {
    const [shouldLogin, toggleShouldLogin] = useToggleState(false);

    return shouldLogin ? (
        <ShouldLogin employerId={employerId} />
    ) : (
        <SaveSearch
            autosearchName={autosearchName}
            employerId={employerId}
            toggleShouldLogin={toggleShouldLogin}
            toggleShowModal={toggleShowModal}
        />
    );
};

export default SubscribeModal;
