import { useState, FC } from 'react';

import { VSpacing } from '@hh.ru/magritte-ui';

import NoVacancies from 'lux/components/Employer/VacancySearch/NoVacancies';
import SearchContentLoader from 'lux/components/Employer/VacancySearch/SearchContentLoader';

const VacancySearch: FC = () => {
    // todo HH-225250
    const [isLoading] = useState(false);

    return (
        <>
            <VSpacing default={24} xs={16} />
            {isLoading ? <SearchContentLoader /> : <NoVacancies />}
        </>
    );
};

export default VacancySearch;
