import { Fragment } from 'react';

import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ContainerForXSL from 'lux/components/CodeInjector/ContainerForXSL';
import Portal from 'lux/components/CodeInjector/Portal';
import ChameleonHeader from 'lux/components/Employer/ChameleonHeader';
import Subscribe from 'lux/components/Employer/Sidebar/Subscribe';
import SubscribeProvider from 'lux/components/Employer/Subscribe/SubscribeProvider';
import VacanciesFilter from 'lux/components/Employer/VacanciesFilter';
import EmployerReviewsBigWidget from 'lux/components/EmployerReviews/WidgetBig';
import FullPageLayout from 'lux/components/PageLayout/FullPageLayout';
import RowContent from 'lux/components/RowContent';
// tempexp_26879_start
import WantSame from 'lux/components/VacancyView/WantSame';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    subscribteText: 'employer.view.vacancy.autosearch.button',
};

const EmployerChameleon: TranslatedComponent = ({ trls }) => {
    const abortPageContent = useSelector((state) => state.abortPageContent);
    const employerId = useSelector((state) => state.employerInfo?.id);
    const disableChameleonHeader = useSelector((state) => state.disableChameleonHeader);
    const hideDefaultSubscribe = useSelector((state) => state.hideDefaultSubscribe);

    const ContentWrapper = disableChameleonHeader ? Fragment : RowContent;

    return (
        <FullPageLayout>
            <ContentWrapper>
                {!disableChameleonHeader && <ChameleonHeader />}
                <ContainerForXSL place="legacy-page-layout-xsl" />
                {/* tempexp_26879_nextline */}
                <WantSame keyPrefix="employerId" id={String(employerId)} />
                <SubscribeProvider>
                    {!abortPageContent && (
                        <Portal place="vacancies-react-content">
                            <VacanciesFilter />
                        </Portal>
                    )}
                    <ColumnsWrapper>
                        <Column l="16" m="12" s="8" xs="4">
                            {!hideDefaultSubscribe && (
                                <div className="employer-branded-savedsearch">
                                    <Subscribe subscribeButtonText={trls[TrlKeys.subscribteText]} />
                                </div>
                            )}
                            <EmployerReviewsBigWidget hasWrapper={false} />
                        </Column>
                    </ColumnsWrapper>
                </SubscribeProvider>
            </ContentWrapper>
        </FullPageLayout>
    );
};

export default translation(EmployerChameleon);
