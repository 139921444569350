import { FC } from 'react';

import Column from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';

import Skeleton, { SkeletonLine, SkeletonRepeat, SkeletonStripe } from 'lux/components/Skeleton';

const SkeletonLoader: FC = () => (
    <Column container xs="4" s="6" m="6" l="6">
        <Skeleton>
            <SkeletonRepeat count={2}>
                <SkeletonLine height={22}>
                    <SkeletonStripe />
                </SkeletonLine>
                <VSpacing base={3} />
                <SkeletonRepeat count={4}>
                    <SkeletonLine>
                        <SkeletonStripe />
                        <SkeletonStripe width={24} />
                    </SkeletonLine>
                    <VSpacing base={1} />
                </SkeletonRepeat>
                <VSpacing base={5} />
            </SkeletonRepeat>
        </Skeleton>
    </Column>
);

export default SkeletonLoader;
