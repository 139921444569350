import { FC } from 'react';

import VacancySearch from 'lux/components/Employer/VacancySearch';
import { useSelector } from 'lux/modules/useSelector';

import VacanciesGroupsByRegion from 'lux/components/Employer/VacanciesGroupsByRegion/VacanciesGroupsByRegion';

const VacanciesBlock: FC<{ hasChameleon?: boolean }> = (props) => {
    const showEmployerVacancySearch = useSelector(({ showEmployerVacancySearch }) => showEmployerVacancySearch);
    return showEmployerVacancySearch ? <VacancySearch /> : <VacanciesGroupsByRegion {...props} />;
};

export default VacanciesBlock;
